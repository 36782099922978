import React from 'react'
import Content from '../Styles/styleOne'
import { Link, SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import img from "~assets/image/media/Recruiters/03.svg";

export default function Uncover({}){

return(
<Content>
  
  <Container>
    <Content.Block>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
    <Col className="col-xl-7 col-lg-6 col-md-8 col-xs-10">
        <Content.Image>
            <img  src={img} alt="content" layout="fullWidth" placeholder="blurred" className='w-100' height={450} />
        </Content.Image>
      </Col>
      <Col xs="12" className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
        <Content.Box>
          <Content.Title as="h2"> <SuperTag value="Expand your talent pool"/> </Content.Title>

          <Content.Text> <SuperTag value="Access a diverse range of candidates from various sources, including LinkedIn groups, events, posts, and recruiter searches. Add candidates to your network and find your next star hire."/> </Content.Text>
        </Content.Box>
      </Col>
    </Row>
    </Content.Block>

    
  </Container>
</Content>
)
}