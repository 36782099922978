import styled from "styled-components/macro"
import { Link } from "~components"
import { Box, Heading, Button, Paragraph } from "~styled"
import {transparentize} from "polished"
const propToColor = (arg = "#442cd6" ) => transparentize(0.70, arg);
const Content = styled(Box)`
  padding-top: 75px;
  padding-bottom: 60px;
  border-bottom: 0px solid rgba(128, 138, 142, 0.2);
  @media (min-width: 768px) {
    padding-top: 100px;
    padding-bottom: 80px;
  }
  @media (min-width: 992px) {
    padding-top: 165px;
    padding-bottom: 135px;
  }
`
Content.Block = styled(Box)`
  border-top: 1px solid rgba(128, 138, 142, 0.2);
  padding-top: 60px;
  padding-bottom: 60px;

  @media (min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media (min-width: 992px) {
    padding-top: 132px;
    padding-bottom: 135px;
  }
`

Content.Block = styled(Box)`
  /* padding-top: 80px; */
  @media (min-width: 768px) {
    padding-top: 0;
  }
`
Content.Title = styled(Heading)`
  color: #fff;
  font-size: 35px;
  font-weight: 500;
  text-align: center;
  letter-spacing: -2px;
  line-height: 1.20689;
  margin-bottom: 32px;

  @media (min-width: 480px) {
    font-size: 28px;
  }

  @media (min-width: 768px) {
    font-size: 38px;
  }

  @media (min-width: 992px) {
    font-size: 44px;
  }
`
Content.Text = styled(Paragraph)`
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.66666;
  margin-bottom: 12px;
`

Content.Image = styled(Box)`
  position: relative;
  margin-bottom: 40px;
  z-index: 1;

  @media (min-width: 1200px) {
    margin-bottom: 0;
    margin-right: 55px;
  }
`
Content.ContentShape = styled(Box)`
    width: 38%;
    position: absolute;
    left: -1%;
    top: -7%;
    z-index: -1;
`
Content.Button = styled(Link)`
border-radius: 10px;
padding: 16px 35px;
margin-top: 40px;
min-width:100%;
color: #5338fc;

@media (min-width:1200px){
        min-width:137px;
        right: 10px;
        transform: translateY(-50%);
        top: 50%;
    }
    &:hover{
      background: #443B9B;
      box-shadow:  ${props => "0 4px 4px " + propToColor(props.hoverBackgroundColor)};
      color: #fff;
    }
`


Content.Box = styled(Box)``

export default Content