import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import Testimonial from '../sections/customer-stories/Testimonial'
import Hero from "~sections/Recruiters/Hero"
import Uncover from "~sections/Recruiters/Sections/uncover"
import Research from "~sections/Recruiters/Sections/research"
import Discover from "~sections/Recruiters/Sections/discover"
import Navigator from "~sections/Recruiters/Sections/navigator"
import GradTwo from "~sections/Recruiters/Sections/grad"
import Response from "~sections/Recruiters/Sections/response"
import { Helmet } from "react-helmet";
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function Recruiters() {
  return (
    <>
    <Helmet>
        <title>Recruiters</title>
        <meta name="description" content="Automate LinkedIn outreach and find top talent faster with We-Connect. Start saving up to 10 hours per week on candidate outreach & sign up today."/>
        <meta property="og:title" content="Recruiters "/>
        <meta property="og:description" content="Automate LinkedIn outreach and find top talent faster with We-Connect. Start saving up to 10 hours per week on candidate outreach & sign up today.  "/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://we-connect.io/recruiters"/>
        <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
        <meta property="twitter:title" content="Recruiters "/>
        <meta property="twitter:description" content="Automate LinkedIn outreach and find top talent faster with We-Connect. Start saving up to 10 hours per week on candidate outreach & sign up today. "/>
        <meta property="twitter:image" content="https://we-connect.io/recruiters.png"/>
    </Helmet>
    <PageWrapper headerConfig={header}>
      <Hero />
      <Response />
      <Uncover />
      <Research />
      <Discover />
      <Navigator />
      <GradTwo />
      <Testimonial />
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}
